import React, { Suspense, lazy } from "react";
import { useRoutes, Navigate } from "react-router-dom";
import PATHS from "./Paths";
// import NavbarLayout from "../layouts/NavbarLayout";
import { useSelector } from "react-redux";

const Loadable = (Component) => (props) => {
  return (
    <Suspense>
      <Component {...props} />
    </Suspense>
  );
};
export default function Router() {
  const auth = useSelector((data) => data.auth);

  return useRoutes([
    { path: "/", element: <Navigate to={PATHS.home} /> },
    { path: PATHS.home, element: <Home /> },
    { path: PATHS.proceed, element: <Proceed /> },
    { path: PATHS.succesfullyProceed, element: <SuccessfullyProceed /> },
    { path: PATHS.shop, element: <Shop /> },
    { path: PATHS.about, element: <About /> },
    { path: PATHS.contactUs, element: <ContactUs /> },
    { path: PATHS.blogs, element: <Blogs /> },
    { path: PATHS.blogDetail, element: <BlogDetail /> },
    { path: PATHS.cart, element: <Cart /> },
    { path: PATHS.productDetails, element: <ProductDetails /> },
    { path: PATHS.trackOrder, element: <TrackOrder /> },
    { path: PATHS.viewAllCategories, element: <ViewAllCategories /> },
    { path: PATHS.viewAllDeals, element: <ViewAllDeals /> },
    { path: PATHS.viewAllTrending, element: <ViewAllTrending /> },
    { path: PATHS.termsAndConditions, element: <TermsAndConditions /> },
    { path: PATHS.refundPolicy, element: <RefundPolicy /> },
    { path: PATHS.privacyPolicy, element: <PrivacyPolicy /> },
    { path: PATHS.profile, element: <Profile /> },
    { path: PATHS.brands, element: <Brands /> },
    { path: PATHS.brandsCategory, element: <BrandsCategory /> },
    { path: PATHS.viewAllProducts, element: <ViewAllProducts /> },
    { path: PATHS.orderHistory, element: <OrderHistory /> },
    { path: PATHS.orderPaymentHistory, element: <OrderPayment /> },
    { path: PATHS.productCategory, element: <ProductCategory /> },
    { path: PATHS.viewAllBannerProducts, element: <ViewAllBannerProducts /> },
    // { path: "*", element: <NotFoundPage /> },
  ]);
}

const Home = Loadable(lazy(() => import("../pages/home/Home")));
const Shop = Loadable(lazy(() => import("../pages/shop/Shop")));
const About = Loadable(lazy(() => import("../pages/about/About")));
const ContactUs = Loadable(lazy(() => import("../pages/contact/Contact")));
const Blogs = Loadable(lazy(() => import("../pages/blogs/Blogs.jsx")));
const BlogDetail = Loadable(lazy(() => import("../pages/blogs/BlogDetail.jsx")));
const Cart = Loadable(lazy(() => import("../pages/cart/Cart")));
const OrderHistory = Loadable(
  lazy(() => import("../pages/trackOrderHistory/TrackOrderHistory.jsx"))
);
const Proceed = Loadable(lazy(() => import("../pages/proceed/proceed.jsx")));
const SuccessfullyProceed = Loadable(
  lazy(() => import("../pages/proceedSuccessfully/proceedSuccess.jsx"))
);

const ProductDetails = Loadable(
  lazy(() => import("../pages/shop/ProductDetails"))
);
const TrackOrder = Loadable(
  lazy(() => import("../pages/trackOrder/TrackOrder"))
);
const ViewAllCategories = Loadable(
  lazy(() => import("../pages/viewAllProducts/ViewAllCategories"))
);
const ViewAllDeals = Loadable(
  lazy(() => import("../pages/viewAllProducts/ViewAllDeals"))
);
const ViewAllTrending = Loadable(
  lazy(() => import("../pages/viewAllProducts/ViewAllTrending"))
);
const TermsAndConditions = Loadable(
  lazy(() => import("../pages/cmsPages/TermsAndConditions"))
);
const RefundPolicy = Loadable(
  lazy(() => import("../pages/cmsPages/RefundPolicy"))
);
const PrivacyPolicy = Loadable(
  lazy(() => import("../pages/cmsPages/PrivacyPolicy"))
);
const Profile = Loadable(lazy(() => import("../pages/profile/Profile")));
const Brands = Loadable(lazy(() => import("../pages/brands/Brands")));
const BrandsCategory = Loadable(
  lazy(() => import("../pages/brands/BrandsCat"))
);
const ViewAllProducts = Loadable(
  lazy(() => import("../pages/viewAllProducts/ViewAllProducts"))
);
const OrderPayment = Loadable(
  lazy(() => import("../pages/trackOrderHistory/OrderPaymentHistory.jsx"))
);
const ProductCategory = Loadable(
  lazy(() => import("../pages/productCategory/ProductCategory.jsx"))
);
const ViewAllBannerProducts = Loadable(
  lazy(() => import("../pages/viewAllProducts/ViewAllBannerProducts.jsx"))
);