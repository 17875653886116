const PATHS = {
  signin: "/signin",
  home: "/home",
  shop: "/shop",
  about: "/about",
  contactUs: "/contactUs",
  blogs: "/blogs",
  blogDetail: "/blog-detail",
  cart: "/cart",
  proceed: "/proceed",
  succesfullyProceed: "/succesfullyProceed",
  productDetails: "/productDetails",
  trackOrder: "/trackOrder",
  viewAllCategories: "/viewAllCategories",
  viewAllDeals: "/viewAllDeals",
  viewAllTrending: "/viewAllTrending",
  termsAndConditions: "/termsAndConditions",
  refundPolicy: "/refundPolicy",
  privacyPolicy: "/privacyPolicy",
  profile: "/profile",
  brands: "/brands",
  brandsCategory: "/brandsCategory",
  viewAllProducts: "/viewAllProducts",
  orderHistory: "/orderHistory",
  orderPaymentHistory: "/orderPaymentHistory",
  productCategory: "/productCategory",
  viewAllBannerProducts: "/viewAllBannerProducts",
};

export default PATHS;
