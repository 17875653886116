import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const firebaseConfig = {
    apiKey: "AIzaSyBM-31VU0X6z8fYTqi1TNsNzgIplmc1hiQ",
    authDomain: "easy-qist.firebaseapp.com",
    projectId: "easy-qist",
    storageBucket: "easy-qist.firebasestorage.app",
    messagingSenderId: "256107447765",
    appId: "1:256107447765:web:eb9e42e0961b4d2bb6695c",
    measurementId: "G-F55VCMGEMP"
};

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

export { messaging, getToken, onMessage };