import { SplitApiSettings } from "./SplitApiSetting";
import { API_END_POINTS } from "./ApiEndpoints";

export const api = SplitApiSettings.injectEndpoints({
  reducerPath: "api",
  refetchOnMountOrArgChange: true,
  endpoints: (builder) => ({
    /////////////////////////////<===MUTATIONS===>//////////////////////////////
    register: builder.mutation({
      query: ({ data }) => ({
        url: API_END_POINTS.register,
        method: "POST",
        body: { ...data },
      }),
    }),

    addToCart: builder.mutation({
      query: ({ data }) => ({
        url: API_END_POINTS.addToCart,
        method: "POST",
        body: data,
      }),
    }),

    proceed: builder.mutation({
      query: ({ data }) => ({
        url: API_END_POINTS.proceed,
        method: "POST",
        body: data,
      }),
    }),

    signUp: builder.mutation({
      query: ({ data }) => ({
        url: API_END_POINTS.signUp,
        method: "POST",
        body: data,
      }),
    }),

    login: builder.mutation({
      query: ({ data }) => ({
        url: API_END_POINTS.login,
        method: "POST",
        body: data,
      }),
    }),

    verifyUser: builder.mutation({
      query: ({ data }) => ({
        url: API_END_POINTS.verifyUser,
        method: "POST",
        body: data,
      }),
    }),

    updateAddress: builder.mutation({
      query: ({ data }) => ({
        url: API_END_POINTS.updateAddress,
        method: "POST",
        body: data,
      }),
    }),

    deleteCartOrder: builder.mutation({
      query: ({ data }) => {
        return {
          url: `${API_END_POINTS.deleteCartOrder}?id=${data?.id}&order_id=${data?.order_id}`,
          method: "GET",
        }
      },
    }),

    deleteOrder: builder.mutation({
      query: ({ data }) => {
        return {
          url: `${API_END_POINTS.deleteOrder}?order_id=${data}`,
          method: "GET",
        }
      },
    }),

    contactUs: builder.mutation({
      query: ({ data }) => {
        return {
          url: API_END_POINTS.contactUs,
          method: "POST",
          body: data,
        }
      },
    }),

    changeOrderStatus: builder.mutation({
      query: ({ data }) => ({
        url: API_END_POINTS.changeOrderStatus,
        method: "POST",
        body: data,
      }),
    }),

    requestProduct: builder.mutation({
      query: ({ data }) => ({
        url: API_END_POINTS.requestProduct,
        method: "POST",
        body: data,
      }),
    }),

    deleteUser: builder.mutation({
      query: ({ data }) => ({
        url: `${API_END_POINTS.deleteUser}?id=${data}`,
        method: "GET",
      }),
    }),

    checkFcmToken: builder.mutation({
      query: ({ data }) => ({
        url: API_END_POINTS.checkFcmToken,
        method: "POST",
        body: data,
      }),
    }),

    saveFcmToken: builder.mutation({
      query: ({ data }) => ({
        url: API_END_POINTS.saveFcmToken,
        method: "POST",
        body: data,
      }),
    }),

    /////////////////////////////<===QUERIES===>////////////////////////////////

    getBrands: builder.query({
      query: ({ params }) => {
        return {
          url: API_END_POINTS.getBrands,
          method: "GET",
          params,
        };
      },
    }),

    getProductCat: builder.query({
      query: () => {
        return {
          url: API_END_POINTS.getProductCat,
          method: "GET",
        };
      },
    }),

    getAllProduct: builder.query({
      query: ({ params }) => {
        return {
          url: API_END_POINTS.getAllProduct,
          method: "GET",
          params,
        };
      },
    }),

    getAllSpecialProd: builder.query({
      query: ({ params }) => {
        return {
          url: API_END_POINTS.getAllSpecialProd,
          method: "GET",
          params,
        };
      },
    }),

    getAllTrendingProd: builder.query({
      query: ({ params }) => {
        return {
          url: API_END_POINTS.getAllTrendingProd,
          method: "GET",
          params,
        };
      },
    }),

    getBanner: builder.query({
      query: () => {
        return {
          url: API_END_POINTS.getBanner,
          method: "GET",
        };
      },
    }),

    getProductDetails: builder.query({
      query: ({ params }) => {
        return {
          url: API_END_POINTS.getProductDetails,
          method: "GET",
          params,
        };
      },
    }),

    viewCartOrders: builder.query({
      query: ({ params }) => {
        return {
          url: API_END_POINTS.viewCartOrders,
          method: "GET",
          params,
        };
      },
    }),

    getOrderPayment: builder.query({
      query: ({ params }) => {
        return {
          url: API_END_POINTS.getOrderPayment,
          method: "GET",
          params,
        };
      },
    }),

    getOrderDetails: builder.query({
      query: ({ params }) => {
        return {
          url: API_END_POINTS.getOrderDetails,
          method: "GET",
          params,
        };
      },
    }),

    getBannerproducts: builder.query({
      query: ({ params }) => {
        return {
          url: API_END_POINTS.getBannerProducts,
          method: "GET",
          params,
        };
      },
    }),

    getOrderHistory: builder.query({
      query: ({ params }) => {
        return {
          url: API_END_POINTS.getOrderHistory,
          method: "GET",
          params,
        };
      },
    }),

    getRequestedProductDetail: builder.query({
      query: ({ params }) => {
        return {
          url: API_END_POINTS.getRequestedProductDetail,
          method: "GET",
          params,
        };
      },
    }),

    getAllBlogs: builder.query({
      query: () => {
        return {
          url: API_END_POINTS.getAllBlogs,
          method: "GET",
        };
      },
    }),

    getBlogById: builder.query({
      query: ({ id }) => {
        return {
          url: `${API_END_POINTS.getBlogById}/${id}`,
          method: "GET",
        };
      },
    }),

  }),

  overrideExisting: true,
});

export const {
  /////////////////////////////<===MUTATIONS===>//////////////////////////////
  useRegisterMutation,
  useAddToCartMutation,
  useProceedMutation,
  useSignUpMutation,
  useLoginMutation,
  useVerifyUserMutation,
  useUpdateAddressMutation,
  useDeleteCartOrderMutation,
  useContactUsMutation,
  useDeleteOrderMutation,
  useChangeOrderStatusMutation,
  useRequestProductMutation,
  useDeleteUserMutation,
  useCheckFcmTokenMutation,
  useSaveFcmTokenMutation,
  /////////////////////////////<===QUERIES===>////////////////////////////////
  useGetBrandsQuery,
  useGetProductCatQuery,
  useGetAllProductQuery,
  useGetAllSpecialProdQuery,
  useGetAllTrendingProdQuery,
  useGetBannerQuery,
  useGetProductDetailsQuery,
  useViewCartOrdersQuery,
  useGetOrderPaymentQuery,
  useGetOrderDetailsQuery,
  useGetBannerproductsQuery,
  useGetOrderHistoryQuery,
  useGetRequestedProductDetailQuery,
  useGetAllBlogsQuery,
  useGetBlogByIdQuery,
} = api;
