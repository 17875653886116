import React, { useEffect, useState } from "react";
import "../../components/formStyleSheet/FormStyling.css";
import { toast } from "react-toastify";
import Button from "../button/Button";
import { useForm } from "react-hook-form";
import ModalComponent from "../modalSetup/Modal";
import { Modal } from "react-bootstrap";
import OtpInput from "react-otp-input";
import {
  useCheckFcmTokenMutation,
  useLoginMutation,
  useSaveFcmTokenMutation,
  useVerifyUserMutation,
} from "../../services/Api";
import { useDispatch, useSelector } from "react-redux";
import { loggedIn } from "../../redux/AuthSlice";
import PhoneInput from "react-phone-input-2";
import SigninModal from "../allModals/SignupModal";
import { messaging, getToken, onMessage } from "../firebaseConfig/FirebaseConfig";

const LoginModal = (props) => {

  const [otp, setOtp] = useState("");
  const [phone, setPhone] = useState("");
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [otpCode, setOtpCode] = useState(null);
  const [fcmToken, setFcmToken] = useState('');
  const dispatch = useDispatch();
  const auth = useSelector((data) => data?.auth);

  useEffect(() => {
    const requestPermission = async () => {
      try {
        const permission = await Notification.requestPermission();
        if (permission === "granted") {
          const token = await getToken(messaging, {
            vapidKey: "BBBa0p3_6OQdXhgJpgXlpZ4EoELwCi1_r3s8BaS1hSUAmC6xx3tcouogVJN5YuhxPYncEta8lnp9B5ipJoV5poA",
          });
          setFcmToken(token);
          console.log("FCM Token:", token);
        } else {
          console.error("Permission denied");
        }
      } catch (error) {
        console.error("Error getting FCM token", error);
      }
    };

    requestPermission();

    onMessage(messaging, (payload) => {
      console.log("Message received: ", payload);
    });
  }, []);

  const {
    handleSubmit,
    formState: { errors },
  } = useForm();

  const signupModalHandler = () => {
    setShowLoginModal((prev) => !prev);
  };

  const [login, { isLoading: signUpLoading }] = useLoginMutation();
  const [verifyUser, { isLoading: verifyLoading }] = useVerifyUserMutation();
  const [checkFcmToken] = useCheckFcmTokenMutation();
  const [saveFcmToken] = useSaveFcmTokenMutation();

  const checkFcmHandler = (id) => {

    const data = {
      user_id: id,
    };

    checkFcmToken({ data: data })
      .unwrap()
      .then((payload) => {
        if (!payload?.status) {
          saveFcmHandler(id);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const saveFcmHandler = (id) => {

    const data = {
      user_id: id,
      fcm_token: fcmToken,
    };

    saveFcmToken({ data: data })
      .unwrap()
      .then((payload) => {

      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const onLogin = (data) => {
    if (!phone) {
      alert("Phone Number is required for login!");
      return;
    }
    console.log("data-->", data);
    let params = {
      phone_no: '+' + phone,
    };

    if (phone?.length == 12) {
      login({ data: params })
        .unwrap()
        .then((payload) => {
          const { code, status, message } = payload;

          if (status && code) {
            setOtpCode(code);
          } else {
            alert(message);
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    } else {
      toast.error("Invalid phone number");
    }
  };

  const onVerifyogin = () => {
    if (!otp) {
      alert("OTP is required!");
      return;
    }
    let params = {
      register: 0,
      sms_code: otp,
      phone_no: `+${phone}`,
    };

    verifyUser({ data: params })
      .unwrap()
      .then((payload) => {
        if (payload.status) {
          dispatch(loggedIn(payload));
          checkFcmHandler(payload?.user?.id);
          props.handleCloseSignupModal();
        } else {
          alert(payload.message);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const handlePhoneChange = (value) => {
    if (!value.startsWith('92')) {
      value = '92' + value.replace(/^92*/, '');
    }
    setPhone(value);
  };

  return (
    <ModalComponent
      size="md"
      show={true}
      handleClose={props.handleCloseSignupModal}
    >
      <Modal.Body className="overflow m-2 lgModals customScroll pt-0 mt-0">
        {!otpCode ? (
          <>
            <h1 className="mainHeading mb-4 text-center">Login</h1>
            <p className="subHeading">Log in with your Phone Number.</p>

            <div className="row">
              <div className="col-12 mt-4">
                <label className="formLabel">Enter Phone No.</label>
                <PhoneInput
                  country={'pk'}
                  value={phone}
                  onChange={handlePhoneChange}
                  onlyCountries={['pk']}
                  disableDropdown={true}
                  placeholder='+92 xxx xxxxxxx'
                />
              </div>

              <div className="col-12 mt-4">
                <Button
                  label="Login"
                  className="greenBtn w-100"
                  onClick={handleSubmit(onLogin)}
                  loading={verifyLoading || signUpLoading}
                />
              </div>

              <h3 className="text-end mt-4 mb-4">Don't have an account? <span className="text-primary cursor" onClick={signupModalHandler}>Signup</span></h3>
            </div>
          </>
        ) : (
          <div className="col-12 otpBox mt-4">
            <label className="formLabel">Enter OTP: An OTP code has sent to <b>+{phone}</b></label>
            <OtpInput
              value={otp}
              inputType="number"
              onChange={setOtp}
              numInputs={4}
              renderSeparator={<span>-</span>}
              renderInput={(props) => <input {...props} />}
            />
            <div className="col-12 mt-4">
              <Button
                label="Verify"
                className="greenBtn w-100"
                onClick={onVerifyogin}
              />
            </div>
          </div>
        )}
      </Modal.Body>

      {showLoginModal && (
        <SigninModal handleCloseSignupModal={signupModalHandler} />
      )}
    </ModalComponent>
  );
};

export default LoginModal;